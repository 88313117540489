<div class="content">
    <mat-card class="login-card">
        <mat-card-title>Login</mat-card-title>
        <mat-card-content>
            <form [formGroup]="loginForm">
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput type="text" formControlName="email" name="email">
                </mat-form-field>
                <br>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" name="password">
                </mat-form-field>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" [disabled]="!loginForm.valid" (click)="login()">Log in</button>
        </mat-card-actions>
    </mat-card>
</div>