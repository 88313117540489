import { Injectable } from '@angular/core';
import { User } from '../types/user';
import { AngularFireAuth } from "@angular/fire/auth"; 
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth, private http: HttpClient) { 
    this.afAuth.setPersistence("session"); 

    this.afAuth.authState.subscribe(user => {
      if (user){
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  user = null;

  async getUser() {
    return this.afAuth.currentUser;
  }

  async getAuthState() {
    return this.afAuth.authState;
  }

  async login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password); 
  }

  async logout() {
    localStorage.removeItem('user');
    return this.afAuth.signOut(); 
  }

  changePassword(email: string, newPass: string) {
    let body = {
      email: email,
      newPassword: newPass
    };

    let url = "https://us-central1-mizzou-isme-hof.cloudfunctions.net/updatePassword";
    
    return this.http.post(url, body).toPromise();
  }

  isLoggedIn(): boolean {
    const  user  =  JSON.parse(localStorage.getItem('user'));
    return  user  !==  null;
  }
}
