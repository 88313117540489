<div class="content">
    <mat-card class="main-card">
        <mat-card-title>Background and Key Qualifications </mat-card-title>
        <mat-card-subtitle>IMSE Hall of Fame</mat-card-subtitle>
        <mat-card-content>
            <b>MISSION</b> - To recognize and honor those industrial engineering graduates from the University of Missouri who are outstanding in their field, leaders in community affairs, and worthy of emulation, and to recognize and honor those persons who have made outstanding contributions to the Department of Industrial and Manufacturing Systems Engineering. 
        </mat-card-content>
        <mat-card-content>
            <b>QUALIFICATIONS</b> – A potential IMSE Hall of Fame member should be recognized as outstanding in his/her chosen field in one or more of the following categories:
        </mat-card-content>
        <mat-card-content>
            <b>DEGREE</b> - A nominee should hold a BS, MS, or PhD degree from the University of Missouri Department of Industrial and Manufacturing Systems Engineering, granted at least fifteen (15) years prior to election. 
        </mat-card-content>
        <mat-card-content>
            <b>ACCOMPLISHMENTS</b> -  A nominee should be recognized for one or more of the following significant accomplishments in his/her career: 
            <ul>
                <li>Be an outstanding engineering practitioner, and/or possess recognized expertise in one or more fields of industrial engineering.</li>
                <li>Be an outstanding and recognized leader who has received distinction in a chosen field other than industrial engineering.</li>
                <li>Be an outstanding and recognized academician, scientist, or researcher in industrial engineering or other chosen field.</li>
            </ul>
        </mat-card-content>
        <mat-card-content>
            <b>PERSONAL ATTRIBUTES</b> - A nominee should be a person worthy of emulation and a person recognized as a leader and a role model for young people.  Such recognition shall be based on one or more of the following qualifications:
            <ul>
                <li>Be a leader of people or a project of unusual scope or concept, or a business that has achieved outstanding success.</li>
                <li>Be active in professional or career organizations.</li>
                <li>Be a recipient of honors in the nominee's profession or career.</li>
                <li>Be active in the community through public service, service organizations, and/or charitable causes.</li>
                <li>Be an active lecturer or writer on subjects related to the nominee’s chosen field.</li>
            </ul>
        </mat-card-content>
    </mat-card>
</div>
