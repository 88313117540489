import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../services/profile.service';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ProfileDialogComponent } from "../profile-dialog/profile-dialog.component";
import { Papa } from 'ngx-papaparse';
import { Profile } from '../types/profile';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(private formBuilder:FormBuilder, 
              private profileService: ProfileService,
              private dialog: MatDialog,
              private papa: Papa,
              private authService: AuthService,
              private router: Router) { 

                this.authService.getAuthState().then(state => {
                  state.subscribe(authState => {
                    authState.getIdTokenResult().then(res => {
                      if (!res.claims.admin) {          
                        this.router.navigate(['/login']);
                      } 
                    });
                  });
                });

              }

  enteredYearForm: FormGroup;
  fileToProcess: File = null;
  fileError: string = "";
  submitCounter = 0;

  profiles;
  currentYear;

  handleFileInput(files: FileList) {
    this.fileToProcess = files.item(0);
  }

  enterYear() {
    this.profiles = null;
    this.currentYear = this.enteredYearForm.get("enteredYear").value.toString();
    this.reloadYear();
  }

  reloadYear() {
    this.profileService.getProfilesForYear(this.currentYear).subscribe(profiles => {
      if (profiles.length != 0)
        this.profiles = profiles;

      else  
        this.profiles = [];
    });
  }

  editProfile(id) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';

    dialogConfig.data = {
        id: id,
        dialogTitle: 'Edit Profile'
    };

    const dialogRef = this.dialog.open(ProfileDialogComponent,
        dialogConfig);

    dialogRef.afterClosed().subscribe(e => {
      this.profiles = null;
      this.reloadYear();
    })
  }

  createProfile() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';

    dialogConfig.data = {
        id: '',
        dialogTitle: 'Create Profile'
    };

    const dialogRef = this.dialog.open(ProfileDialogComponent,
        dialogConfig);

    dialogRef.afterClosed().subscribe(e => {
      this.profiles = null;
      this.reloadYear();
    })
  }

  deleteProfile(name: string, id:string) {
    if(confirm("Are you sure you'd like to delete "+ name + "?")) {
      this.profileService.deleteProfile(id).then(() => {
        this.profiles = null;
        this.reloadYear();
      });
    }
  }

  ngOnInit(): void {
    this.enteredYearForm = this.formBuilder.group({
      enteredYear: ['2000', [
        Validators.required,
        Validators.min(1958)
      ]]
    });

    this.enterYear();
  }

  // TODO: Prevent duplicate name/year combos
  async processBulkUpload() {
    if (this.fileToProcess == null)
      return;

    this.papa.parse(this.fileToProcess, {
        complete: async (result) => {
          if (result.errors.length != 0) {
            this.fileError = "There was a problem parsing the CSV. Please make sure it's formatted correctly.";
            return;
          }

          // remove column line and start processing
          result.data.shift();
          var profList: Profile[] = [];

          // first loop to verify validity before we actually push any data
          for (var profile of result.data) {
            if (profile.length < 2) {
              continue;
            }

            // year is not a number
            if (profile[1].trim() == "" || isNaN(profile[1].trim())) {
              continue;
            }

            // create profiles from csv
            var newProf: Profile = {
              name: profile[0].trim(),
              year: profile[1].trim(),
              link: "",
              title: "",
              isHof: "false",
              date: "",
              ignoreScraper: "false"
            }

            // set title if specified
            if (profile.length >= 3)
              newProf.title = profile[2].trim();

            // set link if specified
            if (profile.length >= 4)
              newProf.link = profile[3].trim();

            // set isHof if specified (only True is counted)
            if (profile.length == 5) {
              if (profile[4] == "Yes" || profile[4] == "yes")
                newProf.isHof = "true";
            }

            profList.push(newProf);
          }

          this.submitCounter = profList.length;

          for (var i = 0; i < profList.length; i++) {
            
            let matches = await this.profileService.addProfileCheckForNameAndYear(profList[i]);

            if (matches.length == 0) {
              await this.profileService.createProfile(profList[i]);
            }
            
            this.submitCounter--;
          }
        }
    });
  }

}
