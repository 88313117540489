<div class="content" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
    <mat-card *ngIf="profiles != null && profiles.length > 0">
        <mat-card-content class="titleButtons">
            <button *ngIf="year != 'all'" mat-mini-fab color="white" aria-hidden="false" aria-label="Last Year" (click)="subYear()">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <div class="titleForm">
                <mat-card-title id="titleText">{{ year }} Graduates</mat-card-title>
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput placeholder="Name" [(ngModel)]="searchTerm">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Sort by</mat-label>
                    <mat-select [(ngModel)]="sortType">
                        <mat-option value="" (click)="sortProfiles()">None</mat-option>
                        <mat-option value="sortName" (click)="sortProfiles()">First Name</mat-option>
                        <mat-option value="sortLastName" (click)="sortProfiles()">Last Name</mat-option>
                        <mat-option value="sortHof" (click)="sortProfiles()">Hall of Fame Status</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button *ngIf="year != 'all'" mat-mini-fab color="white" aria-hidden="false" aria-label="Next Year" (click)="addYear()">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </mat-card-content>
    </mat-card>
    <div class="cardcontainer" *ngIf="profiles != null; else loading">
        <mat-card class="carditem" *ngFor="let profile of profiles | filter:searchTerm">
            <mat-card-title>
                {{profile.name}}
                <span *ngIf="year == 'all'">{{profile.year}}</span>
                <mat-icon 
                    aria-hidden="false" 
                    aria-label="Hall of Fame"
                    *ngIf="profile.isHof == 'true'"
                    >
                    star
                </mat-icon>
            </mat-card-title>
            <mat-card-subtitle>{{profile.title}}</mat-card-subtitle>
            <mat-card-content>
                <a *ngIf="profile.link != null && profile.link != ''; else noLink" href="{{profile.link}}" target="_blank">
                    {{profile.link}}
                </a>
                <ng-template #noLink>
                    No Linkedin profile found.
                </ng-template>
            </mat-card-content>
        </mat-card>

        <mat-card class="carditem" *ngIf="profiles.length < 1">
            <mat-card-title>Sorry!</mat-card-title>
            <mat-card-content>Couldn't find any graduates for that year...</mat-card-content>
            <mat-card-content class="titleButtons">
                <button mat-mini-fab color="white" aria-label="Last Year" (click)="subYear()">
                    <mat-icon>chevron_left</mat-icon>
                </button>                
                <button mat-raised-button routerLink="/">Back to Home</button>
                <button mat-mini-fab color="white" aria-label="Next Year" (click)="addYear()">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </mat-card-content>
        </mat-card>
    </div>
    <ng-template #loading>
        <mat-spinner></mat-spinner>
    </ng-template>
</div>

