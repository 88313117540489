import { Injectable } from '@angular/core';
import { Profile } from '../types/profile';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private firestore:AngularFirestore) { }

  createProfile(p:Profile) {
    // if these aren't present, don't give a date so the API
    // will update it on its next pass
    if (p.link == '' || p.title == '')
        p.date = '';
    else 
        p.date = (new Date()).toString();

    return this.firestore.collection("profiles").add(p);
  }

  editProfile(id:string, p:Profile) {
    if (p.link == '' || p.title == '')
          p.date = '';
    else 
        p.date = (new Date()).toString();

    return this.firestore.collection('profiles').doc(id).update(p);
  }

  deleteProfile(id:string) {
    return this.firestore.collection("profiles").doc(id).delete();
  }

  getProfiles() {

  }

  addProfileCheckForNameAndYear(profile: Profile) {
    return this.firestore.collection("profiles", ref => 
      ref.where('year', '==', profile.year).where('name', '==', profile.name)
    ).snapshotChanges()
     .pipe(take(1))
     .toPromise();
  }

  getProfilesForYear(year:string) {
    return this.firestore.collection("profiles", ref => ref.where('year', '==', year)).snapshotChanges();
  }

  getAllProfiles() {
    return this.firestore.collection("profiles").snapshotChanges();
  }

  getProfileById(id:string) {
    return this.firestore.collection('profiles').doc(id).snapshotChanges();
  }
}
