import { Component, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private router:Router, 
              private authService: AuthService,
              private snackBar: MatSnackBar)
  {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {

        if (this.authService.isLoggedIn()) {

          // this checks if the user is an admin
          this.authService.getAuthState().then(state => {
            state.subscribe(authState => {
              authState.getIdTokenResult().then(res => {
                if (!res.claims.admin) {
                  this.links = [
                    {title: 'Home', link: '/'},
                    {title: 'Qualifications', link: '/qualifications'},
                  ];
                } 
                else {
                  this.links = [
                    {title: 'Home', link: '/'},
                    {title: 'Qualifications', link: '/qualifications'},
                    {title: 'Admin', link: '/admin'},
                    {title: 'Manage Accounts', link: '/manage'}
                  ];
                }
              });
            });
          });
          
        }
        this.changeActiveLink();
      }
   });
  }
   
  title = 'imse-hof';

  activeLink;

  links = [
    {title: 'Home', link: '/'},
    {title: 'Qualifications', link: '/qualifications'}
  ];

  changeActiveLink() {
    this.activeLink = this.router.url;
  }

  goToLogin() {
    if (this.authService.isLoggedIn())
      this.logout();
    else
      this.router.navigate(['/login']);
  }

  logout() {
    if (confirm("Are you sure you'd like to log out?")) {
      this.authService.logout().then(() => {
        this.router.navigate(['/']).then(() => {
          this.displaySnackbar("You have been logged out.");
        });
      });
    }
  }

  displaySnackbar(message) {
    this.snackBar.open(message, null, {
      duration: 5000
  });
}
}
