<div class="main-wrapper">
    <div class="toolbar">
        <mat-toolbar color="primary">
            <span style="margin-left:10px">Mizzou IMSE Alumni Careers</span>
            <span class="spacer"></span>
            <button mat-icon-button (click)="goToLogin()" aria-label="login">
                <mat-icon>login</mat-icon>
            </button>
        </mat-toolbar>
        <nav mat-tab-nav-bar>
            <a mat-tab-link *ngFor="let link of links"
            (click)="activeLink = link.link"
            [active]="activeLink == link.link"
            [routerLink]="link.link"> {{link.title}}</a>
        </nav>
    </div>
    <router-outlet></router-outlet>
    <div class="footer">
        <img class="footerlogo" src="assets/footer_logo.png">
        <div class="footerbuttons">
            <a class="fa fa-facebook" href="https://www.facebook.com/MUEngineering/" target="_blank"></a>
            <a class="fa fa-twitter" href="https://twitter.com/mizzouengineer" target="_blank"></a>
            <a class="fa fa-linkedin" href="https://www.linkedin.com/school/10875505/" target="_blank"></a> 
            <a class="fa fa-instagram" href="https://www.instagram.com/mizzouengineer/" target="_blank"></a>
        </div>
        <div class="footerrow">
            <div class="footercol">
                <div class="footercontact">
                    <mat-divider></mat-divider>
                    <mat-icon>place_outline</mat-icon>
                    <mat-divider></mat-divider>
                </div>
                <p>W1024 Lafferre Hall, Columbia MO 65211</p>
            </div>
            <div class="footercol">
                <div class="footercontact">
                    <mat-divider></mat-divider>
                    <mat-icon>phone_outline</mat-icon>
                    <mat-divider></mat-divider>
                </div>
                <p>Phone: (573) 882-4375</p>
            </div>
            <div class="footercol">
                <div class="footercontact">
                    <mat-divider></mat-divider>
                    <mat-icon>email_outline</mat-icon>
                    <mat-divider></mat-divider>
                </div>
                <p>Email: engineering@missouri.edu</p>
            </div>
        </div>
    </div>
</div>