<div class="content">
    <mat-card class="login-card">
        <mat-card-title>Manage Passwords</mat-card-title>
        <mat-card-content>
            <form [formGroup]="passwordForm">
                <mat-form-field>
                    <mat-label>Account To Modify</mat-label>
                    <mat-select formControlName="accountToModify">
                        <mat-option value="imsealumni_admin@umsystem.edu">IMSE Admin Account</mat-option>
                        <mat-option value="imsealumni@umsystem.edu">IMSE Account</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>New Password</mat-label>
                    <input matInput type="password" formControlName="password" name="password">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Confirm New Password</mat-label>
                    <input matInput type="password" formControlName="confPassword" name="confPassword">
                </mat-form-field>
            </form>
            <p *ngIf="!requestSuccess" class="warn">{{ errorMessage }}</p>
            <p *ngIf="requestSuccess" class="success">{{ errorMessage }}</p>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="changePassword()">Update Password</button>
        </mat-card-actions>
    </mat-card>
</div>