<h2 mat-dialog-title>{{ dialogTitle }}</h2>

<mat-dialog-content *ngIf="profileForm != null">
    <form [formGroup]="profileForm"> 
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input name="name" matInput placeholder="John Doe" formControlName="name">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Year</mat-label>
            <input type="number" name="year" matInput placeholder="2005" formControlName="year">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Title (Optional)</mat-label>
            <input name="title" matInput placeholder="Project Manager - Google" formControlName="title">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Link (Optional)</mat-label>
            <input name="link" matInput placeholder="https://www.linkedin.com/in/john-doe/" formControlName="link">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Hall of Fame Status (Optional)</mat-label>
            <mat-select formControlName="isHof">
                <mat-option value="true">In Hall of Fame</mat-option>
                <mat-option value="false">Not in Hall of Fame</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Ignore Profile in Scraper</mat-label>
            <mat-select formControlName="ignoreScraper">
                <mat-option value="false">Don't Ignore</mat-option>
                <mat-option value="true">Ignore</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions *ngIf="profileForm != null">
    <button class="mat-raised-button"(click)="close()">Close</button>
    <button class="mat-raised-button mat-accent" (click)="submit()" [disabled]="!profileForm.valid">Save</button>
</mat-dialog-actions>