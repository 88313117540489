import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { Profile } from '../types/profile';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private route:ActivatedRoute, 
              private profileService:ProfileService,
              private router:Router) { 

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }
  
  year:string = "";
  profiles = null;
  sortType: string = "";
  searchTerm: string;

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  addYear() {
    let newYear = Number(this.year);
    newYear++;
    this.router.navigate(['/profiles', newYear.toString()]);
  }

  subYear() {
    let newYear = Number(this.year);
    newYear--;
    this.router.navigate(['/profiles', newYear.toString()]);
  }

  sortProfiles() {

    if (this.profiles == null || this.profiles.length < 1)
      return;

    if (this.sortType == "sortName") {
      this.profiles.sort(function (x, y) {
        var nameX = x.name.toUpperCase();
        var nameY = y.name.toUpperCase();

        if (nameX < nameY)
          return -1;

        if (nameX > nameY)
          return 1;

        return 0;
      });
    }

    else if (this.sortType == "sortHof") {
      this.profiles.sort(function (x, y) {
        var hofX = x.isHof;
        var hofY = y.isHof;

        if ((hofX != null && hofX == 'true') && (hofY == null || hofY == 'false'))
          return -1;

        if ((hofY != null && hofY == 'true') && (hofX == null || hofX == 'false'))
          return 1;

        return 0;
      });
    }

    else if (this.sortType == "sortLastName") {
      this.profiles.sort(function (x, y) {
        var xNames = x.name.split(' ');
        var yNames = y.name.split(' ');

        var nameX = xNames[xNames.length - 1].toUpperCase();
        var nameY = yNames[yNames.length - 1].toUpperCase();

        // if we have a parenthetical name, use the name before that
        if (nameX[0] == '(')
          nameX = xNames[xNames.length - 2].toUpperCase();

        if (nameY[0] == '(')
          nameY = yNames[yNames.length - 2].toUpperCase();

        if (nameX < nameY)
          return -1;

        if (nameX > nameY)
          return 1;

        return 0;
      });
    }
  }

  /* Swipe Events - https://stackoverflow.com/questions/42592156/what-is-the-best-way-to-implement-swipe-navigation-in-angular-2 */
  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();
  
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;
  
      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
          const swipe = direction[0] < 0 ? 'next' : 'previous';
          
          if (this.year != "all") {
            if (swipe == 'next')
              this.addYear();
            
            else if (swipe == 'previous')
              this.subYear();
          }
      }
    }
  }

  ngOnInit(): void {
    
    this.year = this.route.snapshot.params.year;

    if (this.year != "all" && isNaN(Number(this.year))) {
      this.router.navigate(['/']);
    }

    // exception for all keyword
    if (this.year == "all") {
      this.profileService.getAllProfiles().subscribe(profiles => {
        if (profiles.length != 0) {

          this.profiles = [];

          profiles.forEach(p => {
            var newProf: any = p;

            this.profiles.push({
              name : newProf.payload.doc.data().name,
              title : newProf.payload.doc.data().title,
              year : newProf.payload.doc.data().year,
              link : newProf.payload.doc.data().link,
              isHof : newProf.payload.doc.data().isHof
            });
          });
        }
        else {
          this.profiles = [];
        }
      });
    }

    // otherwise, use the year
    else {
      this.profileService.getProfilesForYear(this.year).subscribe(profiles => {
        if (profiles.length != 0) {

          this.profiles = [];

          profiles.forEach(p => {
            var newProf: any = p;

            this.profiles.push({
              name : newProf.payload.doc.data().name,
              title : newProf.payload.doc.data().title,
              year : newProf.payload.doc.data().year,
              link : newProf.payload.doc.data().link,
              isHof : newProf.payload.doc.data().isHof
            });
          });
        }
        else {
          this.profiles = [];
        }
      });
    }
  }

}
