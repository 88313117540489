import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-manage-accounts',
  templateUrl: './manage-accounts.component.html',
  styleUrls: ['./manage-accounts.component.css']
})
export class ManageAccountsComponent implements OnInit {

  passwordForm: FormGroup;
  errorMessage: string = "";
  requestSuccess: boolean = false;

  constructor(private authService: AuthService,
              private router: Router,
              private formBuilder: FormBuilder) { 

      this.authService.getAuthState().then(state => {
        state.subscribe(authState => {
          if (!authState) {
            this.router.navigate(['/login']);
          }
          authState.getIdTokenResult().then(res => {
            if (!res.claims.admin) {          
              this.router.navigate(['/login']);
            } 
          });
        });
      });

    }

  async changePassword() {
      this.requestSuccess = false;
      let newPass = this.passwordForm.get('password').value;
      let newPassConf = this.passwordForm.get('confPassword').value
      let email = this.passwordForm.get('accountToModify').value;

      this.errorMessage = "";

      if (newPass.length < 6) {
        this.errorMessage = "Password must be longer than 6 characters.";
        return;
      }
      else if (newPass !== newPassConf) {
        this.errorMessage = "The entered passwords are not the same.";
        return;
      }

      try {
        let result:any = await this.authService.changePassword(email, newPass);
        this.requestSuccess = true;
        this.errorMessage = result.message;
      }
      catch (error) {
        this.errorMessage = "Could not complete the request.";
      }
  }

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      accountToModify: ['imsealumni@umsystem.edu', [
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      confPassword: ['', [
        Validators.required,
        Validators.minLength(6)
      ]]
    });
  }

}
