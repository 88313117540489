import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router:Router, 
              private formBuilder:FormBuilder,
              private profileService:ProfileService) {

    var curDate = new Date();
    this.yearsEnd = curDate.getFullYear() - 14;

    for (let i = this.yearsEnd; i >= 2000; i--) {
      this.recentYears.push(i.toString());
    }

    this.years.push(["1999", "1998", "1997", "1996", "1995"]);
    this.years.push(["1994", "1993", "1992", "1991", "1990"]);
    this.years.push(["1989", "1988", "1987", "1986", "1985"]);
    this.years.push(["1984", "1983", "1982", "1981", "1980"]);
    this.years.push(["1979", "1978", "1977", "1976", "1975"]);
    this.years.push(["1974", "1973", "1972", "1971", "1970"]);
    this.years.push(["1969", "1968", "1967", "1966", "1965", "1964", "1963", "1962", "1961", "1960", "1959", "1958"]);
   }

  yearsBegin = 1958;
  yearsEnd = 0;
  years = [];
  recentYears = [];

  enteredYearForm: FormGroup;
  enteredYear;

  profilePreviewList;

  loadProfiles(year: string) {
    this.profilePreviewList = null;

    this.profileService.getProfilesForYear(year).subscribe(profiles => {
      if (profiles.length != 0) {
        if (profiles.length > 5)
          this.profilePreviewList = profiles.slice(0, 5);
        else
          this.profilePreviewList = profiles;
      }
      else {
        this.profilePreviewList = [];
      }
    });
  }

  clickYear(tabYear:string) {
    this.router.navigate(['/profiles', tabYear]);
  }

  enterYear() {
    this.router.navigate(['/profiles', this.enteredYearForm.get('enteredYear').value]);
  }

  ngOnInit(): void {
    
    this.enteredYearForm = this.formBuilder.group({
      enteredYear: ['', [
        Validators.required,
        Validators.min(1958)
      ]]
    });
  }

}
