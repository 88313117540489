<div class="content">
    

    <div class="bannerImageContainer">
        <div id="imgContainer">
            <h1>Mizzou IMSE Alumni Careers</h1>
        </div>
        <div class="homeimage">
            <img src="assets/students-laff.png">
        </div>
    </div>
    <div>
        <p>The purpose of this website is to provide Mizzou IMSE Alumni Career information. This information can be used to a variety of alumni engagement activities, including facilitating the nomination process for candidates to the University of Missouri Department of Industrial and Manufacturing Systems Engineering Hall of Fame.</p>
        <p>The site includes resumes of individuals downloaded from LinkedIn.</p>
        <p>The resumes downloaded from LinkedIn met the following three criteria:</p>
        <ul>
            <li>individuals claiming as having attended the University of Missouri (173,861)</li>
            <li>and claiming as having studied Industrial Engineering (5,415)</li>
            <li>and claiming as having graduated with a degree in Industrial Engineering before 2007 (988)</li>
        </ul>
    </div>


    <p>Questions regarding this website can be directed to: Amy Rhea at rheaa@missouri.edu</p>

    <div class="buttons">
        <button mat-raised-button routerLink="/qualifications">Hall of Fame Qualifications</button>
        <a mat-raised-button href="assets/Form_1_Nomination_Form_2020_IMSE_Fillable.pdf" target="_blank">2020 Form</a>
    </div>

    <div class="yearform">
        <p><b>Select a year below or enter a year here:</b></p>
        <form [formGroup]="enteredYearForm"> 
            <mat-form-field class="yearinput">
                <mat-label>Year</mat-label>
                <input type="number" name="enteredYear" matInput placeholder="2005" formControlName="enteredYear">
            </mat-form-field>
        </form>
        <div class="buttons">
            <button mat-raised-button (click)="enterYear()" [disabled]="!enteredYearForm.valid">Submit</button>
            <button mat-raised-button routerLink="/profiles/all">View All Alumni</button>
        </div>
    </div>

    <mgl-timeline mobileWidthThreshold=0>
        <mgl-timeline-entry *ngFor="let year of recentYears">
            <mgl-timeline-entry-header (click)="loadProfiles(year)">{{ year }}</mgl-timeline-entry-header>
            <mgl-timeline-entry-content>
                <button mat-button class="yearEntry" (click)="clickYear(year)">Go to Year</button>
                <div *ngIf="profilePreviewList != null; profLoading">
                    <div *ngFor="let profile of profilePreviewList" class="profileEntry">
                        {{profile.payload.doc.data().name}}
                        <a 
                            mat-button 
                            href="{{profile.payload.doc.data().link}}"
                            [disabled]="profile.payload.doc.data().link == '' || profile.payload.doc.data().link == null"
                            target="_blank"
                            >
                            Linkedin
                        </a>
                    </div>
                </div>
                <ng-template #profLoading>
                    <mat-spinner></mat-spinner>
                </ng-template>
            </mgl-timeline-entry-content>
            <mgl-timeline-entry-dot></mgl-timeline-entry-dot>
        </mgl-timeline-entry>

        <mgl-timeline-entry *ngFor="let year of years">
            <mgl-timeline-entry-header> {{ year[year.length - 1] }} - {{ year[0] }}</mgl-timeline-entry-header>
            <mgl-timeline-entry-content>
                <button mat-button class="yearEntry" *ngFor="let y of year" (click)="clickYear(y)">{{ y }}</button>
            </mgl-timeline-entry-content>
            <mgl-timeline-entry-dot></mgl-timeline-entry-dot>
        </mgl-timeline-entry>
    </mgl-timeline>

</div>