<div class="content">
    <mat-card>
        <div class="yearform">
            <p><b>Select a year to load graduates from:</b></p>
            <form [formGroup]="enteredYearForm"> 
                <mat-form-field class="yearinput">
                    <mat-label>Year</mat-label>
                    <input type="number" name="enteredYear" matInput placeholder="2005" formControlName="enteredYear">
                </mat-form-field>
            </form>
            <button mat-raised-button (click)="enterYear()" [disabled]="!enteredYearForm.valid">Go to Year</button>
            <button mat-raised-button (click)="createProfile()">Add Graduate</button>
            
            <p class="fileInputHeader"><b>Import graduates from CSV:</b></p>
            <div class="fileinput" *ngIf="submitCounter == 0; else addingProfiles">
                <button mat-raised-button (click)="processBulkUpload()">Import Profiles</button>
                <input (change)="handleFileInput($event.target.files)" type="file" name="importFile">
            </div>
            <ng-template #addingProfiles>
                <mat-spinner></mat-spinner>
            </ng-template>
            <p class="fileInputHeader">{{ fileError }}</p>
        </div>
    </mat-card>
    <div *ngIf="profiles != null; else loading">
        <div class="profilelist" *ngFor="let profile of profiles">
            <mat-card class="profile">
                <mat-card-title>
                    {{profile.payload.doc.data().name}} - {{profile.payload.doc.data().year}}
                    <span *ngIf="profile.payload.doc.data().ignoreScraper == 'true'">*</span>
                    <mat-icon 
                    aria-hidden="false" 
                    aria-label="Hall of Fame"
                    *ngIf="profile.payload.doc.data().isHof == 'true'"
                    >
                    star
                </mat-icon>
                </mat-card-title>
                <div class="buttons">
                    <button mat-raised-button color="accent" (click)="editProfile(profile.payload.doc.id)"><mat-icon>edit</mat-icon></button>
                    <button mat-raised-button color="warn" (click)="deleteProfile(profile.payload.doc.data().name, profile.payload.doc.id)"><mat-icon>delete</mat-icon></button>
                </div>
            </mat-card>
        </div>
    </div>
    <ng-template #loading>
        <mat-spinner></mat-spinner>
    </ng-template>
</div>
