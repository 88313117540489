import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../services/profile.service';
import { Profile } from '../types/profile';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.css']
})
export class ProfileDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) {id, dialogTitle},
    private profileService: ProfileService,
    private formBuilder: FormBuilder) { 
      this.profileId = id;
      this.dialogTitle = dialogTitle;

      if (this.profileId != null && this.profileId != "") {
        this.profileService.getProfileById(this.profileId).subscribe(profile => {
          let data = profile.payload.data();
    
          if (data !== null && this.isProfile(data)) {

            let isHofVal = 'false';
            let ignoreScraper = 'false';

            if (data.isHof != null && data.isHof == 'true')
              isHofVal = 'true';

            if (data.ignoreScraper != null && data.ignoreScraper == 'true')
              ignoreScraper = 'true';

            this.profileForm = this.formBuilder.group({
              name: [data.name, [
                Validators.required
              ]],
              year: [data.year, [
                Validators.required
              ]],
              title: [data.title, [
              ]],
              link: [data.link, [
              ]],
              isHof: [isHofVal, [
              ]],
              ignoreScraper: [ignoreScraper, [

              ]]
            });
          }
        });
      }
      else {
        this.profileForm = this.formBuilder.group({
          name: ['', [
            Validators.required
          ]],
          year: ['', [
            Validators.required
          ]],
          title: ['', [
          ]],
          link: ['', [
          ]],
          isHof: ['false', [
          ]],
          ignoreScraper: ['false', [  
          ]]
        });
      }
    }

  profileId;
  profileForm: FormGroup;
  dialogTitle: string;

  close() {
    this.dialogRef.close();
  }

  isProfile (p: Profile | any): p is Profile{
    return (<Profile> p).name !== undefined && 
           (<Profile> p).title !== undefined && 
           (<Profile> p).link !== undefined &&
           (<Profile> p).year !== undefined;
  }

  submit() {

    let p:Profile = {
      name: this.profileForm.get('name').value,
      title: this.profileForm.get('title').value,
      link: this.profileForm.get('link').value,
      year: this.profileForm.get('year').value.toString(),
      date: '',
      isHof: this.profileForm.get('isHof').value,
      ignoreScraper: this.profileForm.get('ignoreScraper').value
    } 

    if (this.profileId != null && this.profileId != "") {
      this.profileService.editProfile(this.profileId, p).then(() => {
        this.close();
      })
    }
    else {
      this.profileService.createProfile(p).then(() => {
        this.close();
      });
    }
  }

  ngOnInit(): void {
    
  }

}
